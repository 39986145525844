import React, { useState, useEffect } from 'react';
import './App.css';
import { getWeek } from 'date-fns';

const fetchData = async (duration, setData) => {
  try {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
    const response = await fetch(`${apiBaseUrl}?duration=${duration}`);
    if (!response.ok) {
      console.error(`Failed to fetch data for ${duration}:`, response.statusText);
      return;
    }
    const data = await response.json();
    setData(data.slice(0, 30)); // 获取前30条数据
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const Pagination = ({ currentPage, totalPages, setPage }) => {
  return (
    <div className="pagination">
      {[...Array(totalPages)].map((_, index) => (
        <button key={index} onClick={() => setPage(index + 1)} className={currentPage === index + 1 ? 'active' : ''}>
          {index + 1}
        </button>
      ))}
    </div>
  );
};

const RepoTable = ({ data, currentPage, itemsPerPage, language }) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedData = data.slice(startIndex, startIndex + itemsPerPage);

  return (
    <table>
      <thead>
        <tr>
          <th>{language === 'en' ? 'Repository Name' : '仓库名称'}</th>
          <th>{language === 'en' ? 'Full Repository Name' : '仓库全称'}</th>
          <th>{language === 'en' ? 'Total Stars' : '总星数'}</th>
          <th>{language === 'en' ? 'Forks' : '分支数'}</th>
          <th>{language === 'en' ? 'Description' : '描述'}</th>
          <th>{language === 'en' ? 'Language' : '使用语言'}</th>
          <th>{language === 'en' ? 'Repository URL' : '仓库地址'}</th>
          <th>{language === 'en' ? 'Created At' : '创建时间'}</th>
          <th>{language === 'en' ? 'Star Growth' : '星数增长'}</th>
        </tr>
      </thead>
      <tbody>
        {selectedData.map((repo, index) => (
          <tr key={index}>
            <td>{repo.repo_name}</td>
            <td>{repo.repo_fullname}</td>
            <td>{repo.stargazers_count}</td>
            <td>{repo.forks_count}</td>
            <td>{language === 'en' ?repo.description : repo.description_cn}</td>
            <td>{repo.language || 'N/A'}</td>
            <td><a href={repo.html_url} target="_blank" rel="noopener noreferrer">
              {language === 'en' ? 'View' : '查看'}
            </a></td>
            <td>{new Date(repo.created_at).toLocaleDateString()}</td>
            <td>{repo.stargrowth}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
const HitCounter = () => {
  return (
    <div className="hit-counter-container">
      <div dangerouslySetInnerHTML={{__html: `
        <!-- hitwebcounter Code START -->
        <a href="https://www.hitwebcounter.com" target="_blank">
        <img src="https://hitwebcounter.com/counter/counter.php?page=16414225&style=0006&nbdigits=6&type=ip&initCount=0" title="Counter Widget" Alt="Visit counter For Websites"   border="0" /></a>                                                
      `}} />
    </div>
  );
};
const BeianInfo = () => {
  return (
    <div className="beian-info">
      <a href="http://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">
        京ICP备2024088342
      </a>
    </div>
  );
};
const App = () => {
  const [weekData, setWeekData] = useState([]);
  const [monthData, setMonthData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [language, setLanguage] = useState('en');
  const [selectedLeaderboard, setSelectedLeaderboard] = useState('week');
  const itemsPerPage = 10;
  const currentWeek = getWeek(new Date());

  useEffect(() => {
    fetchData('week', setWeekData);
    fetchData('month', setMonthData);
    fetchData('year', setYearData);
  }, []);

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'zh' : 'en');
  };

  const handleLeaderboardChange = (event) => {
    setSelectedLeaderboard(event.target.value);
    setCurrentPage(1); // Reset to first page when changing leaderboard
  };

  const getSelectedData = () => {
    switch(selectedLeaderboard) {
      case 'week':
        return weekData;
      case 'month':
        return monthData;
      case 'year':
        return yearData;
      default:
        return weekData;
    }
  };
  // 获取当前选中数据的更新日期
  const getUpdateDate = () => {
    const selectedData = getSelectedData();
    return selectedData.length > 0 ? selectedData[0].date : '';
  };
  //设置动态标题
  useEffect(() => {
    const leaderboardTitle = {
      week: language === 'en' ? 'Weekly Leaderboard' : '周爬升榜',
      month: language === 'en' ? 'Monthly Leaderboard' : '月度爬升榜',
      year: language === 'en' ? 'Yearly Leaderboard' : '年度爬升榜',
    };
    const pageTitle = `${leaderboardTitle[selectedLeaderboard]} - Github AI Repos`;
    document.title = pageTitle; // 动态设置标题
  }, [selectedLeaderboard, language]);
  return (
    <div className="App">
      <div className="language-toggle">
        <button onClick={toggleLanguage}>
          {language === 'en' ? '中文' : 'English'}
        </button>
      </div>
      <h1 className="centered-title">
        {language === 'en'
          ? `Github AI Repository Growth Leaderboard - Week ${currentWeek}, ${new Date().getFullYear()}`
          : `${new Date().getFullYear()}年第 ${currentWeek} 周 Github AI 仓库星数增长榜单`}
      </h1>

      <div className="main-content">
        <div className="leaderboard-select">
          <select value={selectedLeaderboard} onChange={handleLeaderboardChange}>
            <option value="week">{language === 'en' ? 'Weekly Leaderboard' : '周爬升榜'}</option>
            <option value="month">{language === 'en' ? 'Monthly Leaderboard' : '月度爬升榜'}</option>
            <option value="year">{language === 'en' ? 'Yearly Leaderboard' : '年度爬升榜'}</option>
          </select>
        </div>

        <RepoTable 
          data={getSelectedData()} 
          currentPage={currentPage} 
          itemsPerPage={itemsPerPage} 
          language={language} 
        />
        <Pagination 
          currentPage={currentPage} 
          totalPages={Math.ceil(getSelectedData().length / itemsPerPage)} 
          setPage={setCurrentPage} 
        />
      </div>
      
      <footer className="footer">
        <div className="update-date">
          {language === 'en' ? 'Last Updated: ' : '最后更新时间：'}{getUpdateDate()}
        </div>
        <div className="beian-info">
          <a href="http://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">
            京ICP备2024088342
          </a>
        </div>
        <HitCounter />
      </footer>
    </div>
  );
};
export default App;
